import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface GraphQlResponse {
  data: any;
  errors: any;
}

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  private _env = environment;
  constructor(private _http: HttpClient) {}

  get ($query: string): Observable<GraphQlResponse> {
    return this._http.post<GraphQlResponse>(this._env.apiGraphqlUrl, {
      query: $query,
      variables:{}
    });
  }

  getDam ($query: string): Observable<GraphQlResponse> {
    return this._http.post<GraphQlResponse>(this._env.apiGqlDamUrl, {
      query: $query,
      variables:{}
    });
  }

}
